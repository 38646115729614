@tailwind base;
@tailwind components;
@tailwind utilities;


body {
    font-family: 'Montserrat', sans-serif;
}



/* Hide scrollbar for Chrome, Safari and Opera */
::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
html {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

/* Ensure the body takes up full height */
body {
    min-height: 100vh;
    overflow-y: scroll; /* Allow scrolling */
}


@keyframes slideRight {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(0);
    }
}

.group:hover .bg-white {
    animation: slideRight 0.3s forwards;
}